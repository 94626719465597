import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getVehicleOffers, VehicleShort } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetOffers } from './queryKeys'

export const useQueryGetOffers = (): UseQueryResult<ReadonlyArray<VehicleShort>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetOffers,
    queryFn: getVehicleOffers
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
