import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import ScrollContainer from 'react-indiana-drag-scroll'
import styles from './ScrollableDiv.module.css'

export type Props = Readonly<{
  className?: string
}> &
  React.PropsWithChildren

export const ScrollableDiv: React.FC<Props> = ({ className, children }) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const scrollContainerRef = useRef<HTMLElement>(null)

  const handleStartScroll = (): void => {
    setIsScrolling(true)
  }

  const handleEndScroll = (): void => {
    setTimeout(() => setIsScrolling(false), 100)
  }

  const handleClick = (event: React.MouseEvent): void => {
    if (isScrolling) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  return (
    <ScrollContainer
      className={clsx(styles.scrollableDivContainer, className)}
      onStartScroll={handleStartScroll}
      onEndScroll={handleEndScroll}
      innerRef={scrollContainerRef}
      onClick={handleClick}
    >
      {children}
    </ScrollContainer>
  )
}
